import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Counties from "../Data/Counties";
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const StyledButton = styled.button`
  border: 0;
  padding: 0;
  background: none;
`;
const StyledLink = styled(Link)`
  color: rgba(23, 0, 0, 0.7);
  font-weight: bold;
  &:hover {
    text-decoration: underline;
  }
`;

const SelectBox = (props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);

  return (
    <Router>
      <ButtonDropdown class="dropright" isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle>
          <FontAwesomeIcon icon="map-marked-alt" size="2x" />
        </DropdownToggle>
        <DropdownMenu right>
          {Counties.map((value, index) => {
            return (
              <DropdownItem onClick={() => window.location.reload(false)}>
                <StyledButton>
                  <StyledLink to={`./${Counties[index].County}`}>
                    {Counties[index].County}
                  </StyledLink>
                </StyledButton>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </ButtonDropdown>
      <Route exact path="/">
        <Redirect to="/Northamptonshire" />
      </Route>
    </Router>
  );
};

export default SelectBox;
