import React, { useState } from "react";
import "./SearchBox.css";
import "react-toggle/style.css";
import Switch from "react-switch";
import SelectBox from "./SelectBox.js";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import Tags from "../Data/Tags";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  display: grid;
  width: 100%;
  color: white;
  background-color: rgba(0, 0, 0, 0.7);
  padding-top: 2%;
  padding-bottom: 0.5rem;
  grid-template-columns: 1fr 1fr;
`;

const Title = styled.h2`
  text-align: center;
  font-size: 1.9rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  @media only screen and (min-width: 760px) {
    text-align: left;
    padding-left: 2rem;
  }
  @media only screen and (min-width: 1440px) {
    text-align: center;
    padding-right: 25rem;
  }
`;

const SearchDiv = styled.div`
  width: 15rem;
  margin-left: 6%;
  margin-bottom: 5px;
  margin-right:0;
  @media only screen and (min-width: 760px) {
    margin-left: 2rem;
    width: 35rem;
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 1440px) {
    justify-self: center;
    width: 40rem;
    margin-bottom: 1rem;
  }
`;

const ClosedDiv = styled.div`
  padding-top:1.2rem;
  text-align:center;
  margin-right: 2rem;
  grid-column-start:2
  grid-column-end:2
  grid-row-start:2;
  grid-row-end: span 3;
  @media only screen and (min-width: 1440px){
        justify-self: left;
        margin-right: 0;
    }
`;

const ClosedP = styled.p`
  font-size: 0.8rem;
`;

const TagDiv = styled.div`
  justify-self: center;
  margin-left: 5%;
  @media only screen and (min-width: 760px) {
    margin-left: 2rem;
    justify-self: left;
    width: 30rem;
  }
  @media only screen and (min-width: 1440px) {
    justify-self: center;
    width: 30rem;
    margin-right: 10rem;
  }
`;
const RegionDiv = styled.div`
  justify-self: center;
  margin-right: 2.5rem;
  @media only screen and (min-width: 760px) {
    justify-self: center;
    padding-right: 0;
  }
  @media only screen and (min-width: 1440px) {
    justify-self: left;
    margin-left: 0.6rem;
  }
`;

const customStyles = {
  container: (provided, state) => ({
    ...provided,
    width: "15rem",
  }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "red" : "blue",
    padding: 20,
  }),
  singleValue: (provided, state) => {
    const transition = "opacity 300ms";
    return { ...provided, transition };
  },
};

const SearchBox = ({
  searchfield,
  searchChange,
  hidden,
  pushTag,
  selectTags,
  Togglechecked,
}) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const animatedComponents = makeAnimated();

  return (
    <Container>
      <Title>
        OPEN NOW in {window.location.pathname.replace(/\W/g, "")}
      </Title>

      <RegionDiv>
        <SelectBox />
      </RegionDiv>

      <SearchDiv>
        <input
          className="form-control"
          type="search"
          placeholder="Search by name"
          onChange={searchChange}
        />
      </SearchDiv>
      <ClosedDiv>
        <label>
          <Switch
            onChange={hidden}
            checked={Togglechecked}
            height={30}
            width={60}
          />
          <ClosedP>
            <span>{Togglechecked ? "Show" : "Hide"}</span> closed
          </ClosedP>
        </label>
      </ClosedDiv>

      <TagDiv>
        <CreatableSelect
          styles={customStyles}
          isMulti
          placeholder="Filter by tag"
          components={animatedComponents}
          options={selectTags.length > 1 ? [] : Tags}
          className="basic-multi-select"
          blaccNamePrefix="select"
          value={selectTags}
          onChange={pushTag}
          isValidNewOption={(inputValue, selectValue) =>
            inputValue.length > 0 && selectValue.length < 5 && !inputValue
          }
        />
      </TagDiv>
    </Container>
  );
};

export default SearchBox;
