import React, { Component } from "react";
import CardHolder from "./Components/CardHolder";
import SearchBox from "./Components/SearchBox";
import Footer from "./Components/Footer";
import { setSearchField, requestPlaces } from "./action";
import "./App.css";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkedAlt, faSearch, faTags, faBars } from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';
import { css } from "@emotion/core";
import RingLoader from "react-spinners/RingLoader";
import NewsBanner from './Components/NewsBanner'

library.add(fab, faMapMarkedAlt, faSearch, faTags, faBars)

const seo = {
  title: "opennow.uk",
  description:
    "Whats open now in your county.",
  url: "https:opennow.uk"
};

const override = css`
  display: block;
  margin: 0 auto;
  border-color: #21fcc8;
`;

const mapStateToProps = (state) => {
  return {
    searchField: state.searchPlaces.searchField,
    places: state.requestPlaces.places,
    isPending: state.requestPlaces.isPending,
    error: state.requestPlaces.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSearchChange: (event) => dispatch(setSearchField(event.target.value)),
    onRequestPlaces: () => dispatch(requestPlaces()),
  };
};

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: "Shown",
      tags: [],
      checked : false
    };
  }

  handleChange = (options) => {
    !options
      ? this.setState({tags: []}) 
      : this.setState({tags: options})
  }

  changeToggleState = () => {
    this.state.hidden === "Hidden"
      ? this.setState({ hidden: "Shown" })
      : this.setState({ hidden: "Hidden" });
      this.setState(prevState => ({checked: !prevState.checked}));
  };

  componentDidMount() {
    this.props.onRequestPlaces();
  }

  render() {
    const { searchField, onSearchChange, places, isPending } = this.props;
    let filteredTags = this.state.tags.map((obj) =>{
      return obj.label
    }); 
    let tagPlaces;
    this.state.tags.length >= 1 ? 
    tagPlaces = places.filter((places) => {
      return places.type.some(r=>filteredTags.indexOf(r) >= 0)
    })
    : tagPlaces = places

    let togglePlaces;
    this.state.hidden === "Hidden"
      ? (togglePlaces = tagPlaces.filter((tagPlaces) => {
          return !tagPlaces.status.includes("CLOSED");
        }))
      : (togglePlaces = tagPlaces);

    const filteredPlaces = togglePlaces.filter((places) => {
      return places.name.toLowerCase().includes(searchField.toLowerCase());
    });

    return isPending ? (
      <div className="sweet-loading">
      <h1>Loading info...</h1>
        <RingLoader
          css={override}
          size={550}
          color={"#06ffc4"}
        />
        </div>
    ) : (
      <div className="App">
      <Helmet
        title={seo.title}
        meta={[
          {
            name: "description",
            property: "og:description",
            content: seo.description
          },
          { property: "og:title", content: seo.title },
          { property: "og:url", content: seo.url },
        ]}
      />
        <SearchBox
          hidden={this.changeToggleState}
          searchChange={onSearchChange}
          pushTag={this.handleChange}
          selectTags={this.state.tags}
          Togglechecked={this.state.checked}
        />
        <NewsBanner/>
        <CardHolder places={filteredPlaces} />
        <Footer className="footer" />
      </div>
    );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
