import { 
	CHANGE_SEARCH_FIELD,
	REQUEST_PLACES_PENDING,
	REQUEST_PLACES_SUCCESS,
	REQUEST_PLACES_FAILED

} from './constants'
const initialStateSearch = {
	searchField: ''
}
/*
Reducer is a function, that receive action and work on state.
*/
export const searchPlaces = (state = initialStateSearch , action = {}) => {

	switch(action.type) {
		case CHANGE_SEARCH_FIELD:
			// Here we create a new state object, based on initialState with new searchField value  
			return Object.assign({}, state, {searchField: action.payload});
			//using spread
			//return {...state, searchField:action.payload}
		default:
			return state;
	}

}
const initialStatePlaces = {
	isPending: false,
	places: [],
	error:'Unable to load data'
}

export const requestPlaces = (state = initialStatePlaces, action = {}) => {
	switch(action.type) {
		case REQUEST_PLACES_PENDING: 
			return Object.assign({}, state, {isPending: true});
		case REQUEST_PLACES_SUCCESS:
			return Object.assign({}, state, {places: action.payload, isPending: false});
		case REQUEST_PLACES_FAILED:
			return Object.assign({}, state, {error: action.payload, isPending: false});		
		default:
			return state;
	}
}