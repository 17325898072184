const Tags = [
  { value: "Restaurant", label: "Restaurant" },
  { value: "Take-away", label: "Take-away" },
  { value: "Pub", label: "Pub" },
  { value: "Coffee", label: "Coffee" },
  { value: "Garden Centre", label: "Garden Centre" },
  { value: "Countryside", label: "Countryside" },
  { value: "Outdoors", label: "Outdoors" },
  { value: "Sports", label: "Sports" },
  { value: "Children", label: "Children" },
  { value: "Castle", label: "Castle" },
  { value: "Theatre", label: "Theatre" },
  { value: "Racing", label: "Racing" },
  { value: "Gym", label: "Gym" },
  { value: "Recreation", label: "Recreation" },
  { value: "Play Area", label: "Play Area" },
  { value: "Swimming", label: "Swimming" },
  { value: "Opticians", label: "Opticians" },
  { value: "Restaurant", label: "Restaurant" },
  { value: "Cinema", label: "Cinema" },
  { value: "Bakery", label: "Bakery" },
  { value: "Animals", label: "Animals" },
  { value: "Castle", label: "Castle" },
  { value: "Delivery", label: "Delivery" },
  { value: "Shop", label: "Shop" },
  { value: "Clothes", label: "Clothes" },
  { value: "Entertainment", label: "Entertainment" },
  { value: "Home", label: "Home" },
  { value: "DIY", label: "DIY" },
  { value: "Hardware", label: "Hardware" },
  { value: "Beds", label: "Beds" },
  { value: "Cosmetics", label: "Cosmetics" },
  { value: "Museum", label: "Museum" },
  { value: "Estate", label: "Estate" },
  { value: "Tech", label: "Tech" },
];
export default Tags;
