const Counties = [
  {
    County: "Northamptonshire",
    json:
      "https://gist.githubusercontent.com/CFW25/53ec791bc434df35b7143150e711df12/raw/7a7ebcb5f43a789d06c5c82fa21a5b2974fdf756/Northamptonshire.json",
  },
  {
    County: "Buckinghamshire",
    json:
      "https://gist.githubusercontent.com/CFW25/66e0bbc0c9dddbf06502a03cf14128d3/raw/d5bd0def84106916dd2d10e4cfae35f402c54f2d/Buckinghamshire.json",
  },
  {
    County: "Warwickshire",
    json:
      "https://gist.githubusercontent.com/CFW25/49509a38fe70fff285bf08e127bf8370/raw/4656d76e12c7815361c2e9e29f7b41521eef6e49/Warwickshire.json",
  },
  {
    County: "Oxfordshire",
    json:
      "https://gist.githubusercontent.com/CFW25/9bebf619218cb9632a3112de31d5baeb/raw/6a571c329eb615d8045cb2b69c7029055fcd0fd5/Oxfordshire.json",
  },
];
export default Counties;
