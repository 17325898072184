import {
  CHANGE_SEARCH_FIELD,
  REQUEST_PLACES_PENDING,
  REQUEST_PLACES_SUCCESS,
  REQUEST_PLACES_FAILED,
} from "./constants";
import Counties from "./Data/Counties";
import Nationwide from "./Data/Nationwide";

export const setSearchField = (text) => ({
  type: CHANGE_SEARCH_FIELD,
  payload: text,
});

export const requestPlaces = () => (dispatch) => {
  let json;
  switch (window.location.pathname) {
    case `/${Counties[0].County}`:
      json = Counties[0].json;
      break;
    case `/${Counties[1].County}`:
      json = Counties[1].json;
      break;
    case `/${Counties[2].County}`:
      json = Counties[2].json;
      break;
      case `/${Counties[3].County}`:
      json = Counties[3].json;
      break;
    default:
      json = Counties[0].json;
  }
  
  dispatch({ type: REQUEST_PLACES_PENDING });
  fetch(json)
    .then((response) => response.json())
    .then((data) => dispatch({ type: REQUEST_PLACES_SUCCESS, payload: data }))
    .catch((error) =>
      dispatch({ type: REQUEST_PLACES_FAILED, payload: error })
    );
};
