import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createLogger } from "redux-logger";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, combineReducers } from "redux";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { searchPlaces, requestPlaces } from "./reducers";

import "bootstrap/dist/css/bootstrap.min.css";

const logger = createLogger();
const rootReducer = combineReducers({ searchPlaces, requestPlaces });
const store = createStore(
  rootReducer,
  applyMiddleware(thunkMiddleware, logger)
);
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

registerServiceWorker();
