import React from 'react'
import Card from './Card'
import './CardHolder.css'
import styled from "styled-components";

const HolderDiv = styled.div`
    align-content:center;
    text-align:center;
`

const CardHolder = ({places}) => {
    return (
        <HolderDiv>
            <div className="CardHolder-Container">
            {places.map((place, i) => {
                return (
                        <Card
                            key={i}
                            id={places[i].id}
                            name={places[i].name}
                            type={places[i].type}
                            image={places[i].src}
                            status={places[i].status}
                            news={places[i].news}
                            info={places[i].info}
                            />
                            
                );
            })}
        </div>
        </HolderDiv>
    )
}

export default CardHolder


/* What needs including
    Contains the Card/Open elements
    Grid sort of layout

    Map through company/other status and create Card components for each.
    Onclick opens the larger one. Scales with screen size.

*/ 