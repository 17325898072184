import React from 'react';
import './Footer.css'

const Footer = () =>{
return(
    <div className="Footer">
        <p className="FooterP">Copyright 2020 All Rights Reserved</p>
    </div>
)
}
export default Footer;