import React, { useState } from 'react'
import './Card.css'

const Card = ({id,name,type,info,status,image,news}) => {
        const [isShown,setIsShown] = useState(false);
        return (
            <div className="Card">
            <div>
            <h5 className={
                    status === "OPEN" 
                    ? 'background-green'
                    : (status === "CLOSED" 
                    ? 'background-red' 
                    : status === "ONLINE" 
                    ? 'background-blue'
                    : 'background-purple')
                } 
                onMouseEnter={() => setIsShown(true)} 
                onMouseLeave={() => setIsShown(false)}   
                >
                {status}
                </h5>
                {isShown && (
                    info === "none" 
                    ? null
                    :
                    <div className="Card-Popup">
                        {info}
                    </div>
                )}
                </div>
                <h2>{name}</h2>

                <div>{type.map(t=><li key={Math.random(id)}> {t} </li>)}</div>

                <img src={image} alt={name}/>
                
                <div>
                    <a className="Card-Link" href={news}>News</a>
                </div>
                
            </div>
        )
}

export default Card

/* What needs including
    - Logo
    - Name
    - Open/Closed/Partial
    - Favourite toggle (Shows first in list)
    - Type of store
    - What needs to be included 
*/ 