import React from 'react'
import styled from 'styled-components'

const BannerDiv = styled.div`
    text-align:center;
    font-size:1rem;
    font-weight: 500;
    background-color: #00abab;
    color:white;
    padding: 0.3rem 0;
    @media only screen and (min-width: 760px) {
    font-size:2rem;
    }
    @media only screen and (min-width: 1440px) {
    font-size:3rem;
    }
`

const NewsBanner = () => {
    return (
        <BannerDiv>
            Read the government guidance for retailers reopening <span></span>
            <a href="https://www.gov.uk/guidance/working-safely-during-coronavirus-covid-19/shops-and-branches">
                here
            </a>
        </BannerDiv>
    )
}

export default NewsBanner